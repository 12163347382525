import React from 'react'

import Photo from '../../Assets/header/myPhoto.png'
import linkedIn from '../../Assets/header/linkedinLogo.png'
import gitLab from '../../Assets/header/gitlabLogo.png'

import './header.css'

const AboutMeFeature = () => {
  const handleClick = (id) => {
    const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
  }

  return (
    <div className='app__header app__wrapper app__padding'>
      <div className='app__header__welcome'>
        <h1 className='headtext__bebas tracking-in-expand'>hi, i am </h1>
        <h1 className='headtext__bebas tracking-in-expand'>Ridmi perera.</h1>
        <p className='p__manrope '>A front-end developer passionate about building accessible and user friendly websites.</p>
        <div className='app__header__welcome-links'>
          <a href='https://www.linkedin.com/in/ridmi-perera99' target="_blank" rel="noopener noreferrer">
            <img src={linkedIn} alt='linkedin-logo' />
          </a>
          <a href='https://gitlab.com/ridmi1999212' target="_blank" rel="noopener noreferrer">
            <img src={gitLab} alt='gitlab-logo' />
          </a>
        </div>
        <div className='app__header__welcome-buttons'>
          <button className='custom__button' onClick={() => handleClick('experience')}>
            MY EXPERIENCE
          </button>
          <button className='custom__button' onClick={() => handleClick('capabilities')}>
            MY CAPABILITIES
          </button>
        </div>
      </div>
      <div className='app__header__img'>
        <img src={Photo} alt='my photo' data-aos="slide-left" data-aos-duration="1000" />
      </div>     
    </div>
  )
}

export default AboutMeFeature
