import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import Navbar from './Comps/navbar/Navbar';
import { AboutMe, MyProjects, ContactMe } from './Pages';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {

  AOS.init()


  return (
    <BrowserRouter>
    <div className='App'>
      <Navbar/>
				<div>
					<Routes>
						<Route path="/" element={<AboutMe />} />
            <Route path="/myProjects" element={<MyProjects />} />
						<Route path="/contact" element={<ContactMe />} />						
					</Routes>
				</div>      
    </div>
    </BrowserRouter>
  );
}

export default App;
