import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../Assets/header/logo.png'
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={logo} alt="app__logo" />
      </div>
      <div className='app__navbar-links'>
        <NavLink to="/" className='p__manrope' activeClassName="active-link">
          About Me
        </NavLink>
        <NavLink to="/myProjects" className='p__manrope' activeClassName="active-link">
          My Projects
        </NavLink>
        <NavLink to="/contact" className='p__manrope' activeClassName="active-link">
          Contact Me
        </NavLink>
      </div>
      <div className="app__navbar-smallscreen">
        <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <RiCloseLine color="#fff" size={27} className='overlay__close' onClick={() => setToggleMenu(false)} />
            <div className='app__navbar-smallscreen_links'>
              <NavLink to="/" activeClassName="active-link" onClick={() => setToggleMenu(false)}>
                About Me
              </NavLink>
              <NavLink to="/myProjects"  activeClassName="active-link" onClick={() => setToggleMenu(false)}>
                My Projects
              </NavLink>
              <NavLink to="/contact"  activeClassName="active-link" onClick={() => setToggleMenu(false)}>
                Contact Me
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
