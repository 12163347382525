import React from 'react'

import { AboutMeFeature, MyCapabilities, MyExperience, Header } from '../../Containers'

import './aboutMe.css'

const AboutMe = () => {
  return (
    <div>
      <Header />
      <AboutMeFeature />
      <MyCapabilities />
      <MyExperience />
    </div>
  )
}

export default AboutMe
