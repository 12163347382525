import React from 'react'

import ResumeButton from '../../Comps/resumeButton/ResumeButton'

import './aboutMeFeature.css'

const AboutMeFeature = () => {
  
  return (
    <div className='app__aboutfeature app__wrapper app__padding' data-aos="fade-up" data-aos-duration="2000">
      <div className='app__aboutfeature__title'>
        <h1 className='headtext__bebas'>about me</h1>
      </div>      
      <div className='app__aboutfeature__desc'>
        <h1 className='head__manrope'>I am a front-end developer. 
        Has Computer Science & Mathematics background</h1>
        <p className='p__manrope'>
        I am a front-end developer with a passion for creating engaging web experiences through data-driven animations and responsive design. With expertise in technologies like D3.js, React.js, and JavaScript, I have developed interactive websites and animations, enhancing user engagement and improving user interfaces.
        </p>
        <br />
        <p className='p__manrope'>
        I hold a <span className='bold'>Bachelor of Science in Physical Science and ICT</span> from the <span className='bold'> University of Sri Jayawardenapura, Sri Lanka</span>
        , which has provided me with a solid foundation in both theoretical and practical aspects of technology. This background has been instrumental in my ability to solve complex problems and deliver effective digital solutions.
        </p>

        <ResumeButton />
      </div>
      
    </div>
  )
}

export default AboutMeFeature
