import React from 'react'
import './myCapabilites.css'

const MyCapabilities = () => {
  return (
    <div className='app__capabilities app__wrapper app__padding' data-aos="fade-up" data-aos-duration="2000" id='capabilities'>
      <h1 className='headtext__bebas'>My Capabilities</h1>
      <div className='app__capabilities__buttons'>
        <button>html</button>
        <button>css</button>
        <button>javascript</button>
        <button>react.js</button>
        <button>redux</button>
        <button>figma</button>
        <button>d3.js</button>
        <button>jsx</button>
        <button>mern</button>
        <button>typescript</button>
        <button>git</button>
        <button>mysql</button>
      </div>
    </div>
  )
}

export default MyCapabilities
