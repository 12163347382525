import React from 'react'

import Project from '../../Comps/project/Project'
import MiniProject from '../../Comps/miniProject/MiniProjects'
import { projectsArray } from './projectsInfo'
import { miniProjectsArray } from './miniProjectsInfo'

import './myProjects.css'

const MyProjects = () => {
  return (
    <div className='app__projects app__padding' data-aos="flip-down" data-aos-duration="1500">
      <h1 className='headtext__bebas'>Featured Projects</h1>
      <p className='p__manrope'>Here are some of the selected projects that showcase my passion for </p>
      <p className='p__manrope'>front-end development.</p>
      <div className='app__projects__project'>
        {projectsArray.map((project) => (
          <Project 
            projectName={project.projectName}
            desc={project.desc}
            year={project.year}
            role={project.role}
            completeStatus={project.complteStatus}
            url={project.url}
            tech={project.tech}
            img={project.img}
          />

        ))}
          
          {miniProjectsArray.map((project) => (
            <MiniProject
            projectName={project.projectName}
            desc={project.desc}
            url={project.url}
            img={project.img}
          />
          ))}
      </div>
    </div>
  )
}

export default MyProjects
