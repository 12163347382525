import React from 'react'
import './miniProject.css'
import gitLab from '../../Assets/header/gitlabLogo.png'

const MiniProject = ({projectName,desc,url,img}) => {
  return (
    <div className='app__miniProject app__wrapper' data-aos="fade-up" data-aos-duration="1500">
      <img src={img} alt='project image' />
      <div className='app__project__desc'>
        <h1 className='head__manrope'>{projectName}</h1>
        <div className='app__miniProjects__desc-p'>
          <p className='p__manrope'>{desc}</p>
        </div>
        <div className='app__miniProject__desc-info'>
          <hr />
          <br />
          <div className='app__miniProject__desc-info-link p__manrope'>
              <a href={url} target="_blank" rel="noopener noreferrer">
                <img src={gitLab} alt='gitlab-logo' />
              </a>
          </div>
        </div>       
      </div>
    </div>
  )
}

export default MiniProject
