import React from 'react'

const ResumeButton = () => {
    
    const handleDownload = async () => {
        try {
          const response = await fetch('/myCV.pdf'); // Relative path to the file
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'CV-RidmiPerera.pdf'; // Desired file name
          document.body.appendChild(a);
          a.click();
          a.remove();
        } catch (error) {
          console.error('There was a problem with the download:', error);
        }
      };

    return (
        <div>
            <button className='custom__button' onClick={handleDownload}>
                Download Resume
            </button>
        </div>
    )
}

export default ResumeButton
