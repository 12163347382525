import React from 'react'

import linkedIn from '../../Assets/header/linkedinLogo.png'
import gitLab from '../../Assets/header/gitlabLogo.png'
import ResumeButton from '../../Comps/resumeButton/ResumeButton'

import './contactMe.css'

const ContactMe = () => {
  return (
    <div className='app__contact flex__center' data-aos="fade-up" data-aos-duration="1500">
    <h1 className='headtext__bebas'>Let’s connect</h1>
    <p className='p__manrope'>Say hello at</p>
    <p className='p__manrope'>ridmi1999212@gmail.com</p>
    <hr className='contact__divider' />
    <div className='app__contact__number'>
      <p className='p__manrope'>For more info : </p>
      <p className='p__manrope'>+9476750173</p>
    </div>
    <div className='app__contact__links'>
      <ResumeButton />
      <a href='https://www.linkedin.com/in/ridmi-perera99' target="_blank" rel="noopener noreferrer">
        <img src={linkedIn} alt='linkedin-logo' />
      </a>
      <a href='https://gitlab.com/ridmi1999212' target="_blank" rel="noopener noreferrer">
        <img src={gitLab} alt='gitlab-logo' />
      </a>
    </div>
  </div>
  
  )
}

export default ContactMe
