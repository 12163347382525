import img1 from '../../Assets/miniProjects/mini1.png'

const miniProjectsArray = [
    {   projectName:'Simple Shopping Cart using Redux Toolkit',
        desc:"Developed a responsive shopping cart web app using React and Redux Toolkit, focusing on state management.",
        url:"https://gitlab.com/mini_projects1/shopping_cart_redux.git",
        img:img1
    },
]

export {
    miniProjectsArray
}